import React from "react";
import Box from "reusecore/src/elements/Box";
import Text from "reusecore/src/elements/Text";
import Heading from "reusecore/src/elements/Heading";
import SectionLayout from "../../components/SectionLayout";
import data from "./data.js";

const PolicySection = ({ row, col }) => {
  return (
    <SectionLayout sectionId="privacy-policy" title="Privacy Policy">
      <Box className="row" {...row} justifyContent="center">
        <Box className="col" {...col} flexDirection="column" flexBox={true}>
          <p>
            <a
              href="https://d34cuim5v38p5e.cloudfront.net/legal/VisualEyes/Data-Protection-Policy.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>{" "}
            to see our data policy.
          </p>
          {/* {data.map(section => (
            <>
              <Heading style={{ color: "#32325d" }} content={section.title} />
              {section.content.map(paragraph => (
                <Text style={{ color: "#5d646d" }} content={paragraph} />
              ))}
            </>
          ))} */}
        </Box>
      </Box>
    </SectionLayout>
  );
};

PolicySection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap"
  },
  col: {
    width: [1, 2 / 3, 2 / 3, 2 / 3]
  }
};

export default PolicySection;
