import React from "react";
import PolicySection from "../containers/PolicySection";
import MainLayout from "../components/MainLayout";

export default () => {
  return (
    <MainLayout
      title="Policy"
      pathname="/policy"
      description="One of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by VisualEyes and how we use it."
    >
      <PolicySection />
    </MainLayout>
  );
};
